/* File: client/src/styles/main.css */
body {
margin: 0;
font-family: sans-serif;
background-color: #f8f8f8;
}

button {
cursor: pointer;
}

.garden-canvas {
width: 100%;
height: 100%;
background: #fff;
outline: none; /* so we can use tabIndex for keyboard events */
}
