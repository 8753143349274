/* File: client/src/components/Garden/GardenCanvas.css */

.garden-canvas {
  position: relative;
  touch-action: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

/*
  [FIXED] We remove the direct transform scaling from .canvas-grid
  and let GardenCanvas.js dynamically set backgroundSize & backgroundPosition.
*/
.canvas-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.3;
  z-index: 1; /* keep it behind overlays and local cursor */
}
